@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400&display=swap');

body {
  margin: 0;
  /* font-family:  -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', */
  font-family: 'Source Sans 3', sans-serif, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.app-container {
  height: 100%;
}

.rotate-icon {
  transform: rotate(90deg);
}

.side-icon {
  transition: transform 0.1s ease-in;
}

.table-row {
  overflow: hidden;
  transition: height 0.3s ease-in-out;
}

/* Set the initial height to 0, and expand on condition */
.table-row.closed {
  height: 0;
}

.table-row.open {
  height: 0;
}

.icon-card {
  box-shadow:
    0px 100px 104px rgba(11, 79, 119, 0.07),
    0px 38.011px 37.9617px rgba(11, 79, 119, 0.0540974),
    0px 19.9876px 18.4297px rgba(11, 79, 119, 0.0503283),
    0px 10.7864px 9.03458px rgba(11, 79, 119, 0.0468481),
    0px 4.72186px 3.57229px rgba(11, 79, 119, 0.0395441);
}

.help-image {
  width: 10%;
  margin: 0 auto;
}
.error-label {
  display: block;
  color: red;
}
.form-control {
  /* font-family: system-ui, sans-serif; */
  font-size: 1rem;
  font-weight: bold;
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
}
.form-control input[type='checkbox'] {
  cursor: pointer;
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  -moz-appearance: none;
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: #d1d5db;
  width: 1.18em;
  height: 1.18em;
  border: 0.14em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
  margin-left: 16px;
}
.form-control input[type='checkbox']::before {
  content: '';
  width: 0.65em;
  height: 0.65em;
  -webkit-clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: #fff;
}

.form-control input[type='checkbox']:checked::before {
  transform: scale(1);
}

.form-control input[type='checkbox']:disabled {
  --form-control-color: var(--form-control-disabled);
  color: var(--form-control-disabled);
  cursor: not-allowed;
}
.form-control input[type='checkbox']:checked {
  border: none;
  background-color: #0bbbef;
}
.whitespace {
  white-space: initial;
}
.expand-nav-btn {
  z-index: 1300;
  top: 72px;
  position: absolute;
  right: -16px;
  /* transform: translateX(-50%); */
  /* left: 176px; */
  /* left: 65px; */
  background-color: #fff;
}
.retime-logo {
  width: 69px;
  height: 46px;
}
.empty-folder {
  height: 160px;
  margin-top: 100px;
}
.download-studies-modal {
  .MuiTypography-h6 {
    justify-content: center;
  }
}
.customer .table-wrapper {
  height: calc(100% - 54px);
}
.customer .table-container {
  height: calc(100% - 134px);
}
.toolbar-wrapper {
  min-height: 80px !important;
}
