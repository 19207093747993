/*  */

.logo {
  width: 69px;
  height: 46px;
  vertical-align: middle;
  overflow-clip-margin: content-box;
  overflow: clip;
  border-style: none;
}
.otp-logo {
  width: 100px;
  height: 70px;
}
.circle-input {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  font-size: 24px;
  margin: 5px;
  border: 1px solid #ccc;
}
.user-profile {
  cursor: pointer;
  width: 100%;
  height: auto;
  border-radius: 15px;
  object-fit: cover;
  /* border: dashed 1px; */
  padding: 8px;
  border-color: rgba(145, 158, 171, 0.24);
}
